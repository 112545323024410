<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.form.fut_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.file_upload_template_id" disabled label="Template Rec Id" outlined></v-text-field>                                                                
                                <v-text-field v-model="form.fut_name" label="Name" :rules="inputRules" outlined></v-text-field>
                                <v-select
                                    v-model = "form.fut_type_code"
                                    :items="futTypeCodeList"
                                    item-text="description"
                                    item-value="code"
                                    label="Type"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>
                                <v-textarea v-model="form.fut_description" label="Description" outlined></v-textarea>
                                <v-select
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>

                <v-toolbar  dark class="primary mt-5"><v-toolbar-title>Mapping Field{{ saveIndicator }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon  @click="save"><v-icon>save</v-icon></v-btn>
                </v-toolbar>

                <v-card class="mt-1">
                    <v-list>
                        <template v-for="(field,index) in futMappingList"
                            >
                        <v-list-item :key="'index' + index">
                            <v-list-item-content>
                                <v-select
                                    v-model="futMappingList[index]['col']"
                                    label="Excel Col"
                                    outlined
                                    :items="excelColsList"
                                >
                                </v-select>
                                <v-select
                                    v-model="futMappingList[index]['field']"
                                    label="Mapping to"
                                    outlined
                                    :items="mappingFieldList"
                                >
                                </v-select>
                                <v-select
                                    v-model="futMappingList[index]['type']"
                                    label="Type"
                                    outlined
                                    :items="fieldTypeList"
                                    item-value="code"
                                    item-text="description"
                                >
                                </v-select>                                

                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn :disabled="index==0" small icon dark class="primary" @click="moveItem('up',index)">
                                    <v-icon>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn small icon dark class="error" @click="removeItem(index)">
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <v-btn :disabled="index==futMappingList.length-1" small icon dark class="primary" @click="moveItem('down',index)">
                                    <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="'divider'+index"></v-divider>
                        </template>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon dark class="success" @click="addMappingField()">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>                

            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                

                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="info in systemInfo" :key="info.alias" :value="form[info.name]" disabled :label="info.alias" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApFileUploadService from "@/services/ApFileUploadService"
import ApParameterService from "@/services/ApParameterService"
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Batch Upload',disabled: false,href:'/',},
              {text:'Template',disabled: false,href:'/fileupload/templates',},
              {text:'Details',disabled: false,href:'#',},
              ],
            status_list:[],
            futTypeCodeList:[],
            
            excelColsList:[],
            mappingFieldList:[],
            futMappingList:[],
            saveIndicator:"",
            fieldTypeList:[],


            routeParam:[],
            form:{login_id:null,file_upload_template_id:0},
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

            logo:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  async created() {
    this.routeParam['file_upload_template_id'] = this.$route.params.file_upload_template_id;
    //this.routeParam['fut_name'] = atob(this.$route.params.enc_fut_name);
    this.getParameter();
    this.viewDetail();

  },
  computed:{
      filteredExcelCols(){
          let tempList = this.futTypeCodeList.filter(item => item.code === this.form.fut_type_code);
          let returnArray = [];
          if(tempList[0])
           returnArray = tempList[0].excelCols;
          
          return returnArray;
      },
      filteredMappingFields(){
          let tempList = this.futTypeCodeList.filter(item => item.code === this.form.fut_type_code);
       
          let returnArray = [];
          if(tempList[0])
            returnArray = tempList[0].mappingFields;

          return returnArray;
      },
      
  },
  
  methods:{

    async viewDetail(){
        this.form.file_upload_template_id = this.routeParam.file_upload_template_id
        try{
            ApFileUploadService.view_fu_template(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                //console.log(res.data);
            this.excelColsList = this.filteredExcelCols;
            this.mappingFieldList = this.filteredMappingFields;
            if(this.form.fut_mapping){
                this.futMappingList = this.form.fut_mapping;
            }
               
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View File Upload Templates',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        this.form.fut_mapping = this.futMappingList;
        //console.log(this.futMappingList);
        if(this.$refs.mainForm.validate()) {        
            try{
                ApFileUploadService.update_fu_template(this.form).then(() => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','Update Notification Template','Success.');
                    this.saveIndicator = "";
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Update Notification Template',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
    async getParameter(){
        try{
            let parameter = {login_id:"",param_type:"add-fu_template"}
            ApParameterService.get_parameter(parameter).then((res) => {
                this.futTypeCodeList = res.data.futTypeCodeList;
                this.status_list = res.data.statusList; 
                this.fieldTypeList = res.data.fieldTypeList;
                //console.log(res.data);
                
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    addMappingField(){
        let tmpArray;
        tmpArray = {"col":"","field":"","type":"text"};

        this.futMappingList.push(tmpArray);
        this.saveIndicator = "(*)"
        //console.log(this.futMappingList);
    },
    removeItem(index){
        this.futMappingList.splice(index,1)
        this.saveIndicator = "(*)"
    },
    moveItem(direction,index){
        this.saveIndicator = "(*)"
        let tmpArray1 = [];
        let tmpArray2 = [];
        switch (direction) {
            case 'up':
                if(index==0)
                    return;
                tmpArray1 = this.futMappingList[index-1];
                tmpArray2 = this.futMappingList[index];
                this.$set(this.futMappingList,index-1,tmpArray2);
                this.$set(this.futMappingList,index,tmpArray1);
                break;
            case 'down':
                if(index==this.futMappingList.length-1)
                    return;
                tmpArray1 = this.futMappingList[index+1];
                tmpArray2 = this.futMappingList[index];
                this.$set(this.futMappingList,index+1,tmpArray2);
                this.$set(this.futMappingList,index,tmpArray1);
                break;       
            default:
                break;
        }
    }
  }
}
</script>